@use '@angular/material/prebuilt-themes/indigo-pink.css' as *;

@use './assets/styles/abstracts/variables' as *;

@use './assets/styles/abstracts/mixins' as *;

@use './assets/styles/base/reset' as *;

@use './assets/styles/components/mat-dialog-override' as *;

@use '@ctrl/ngx-emoji-mart/picker' as *;

@use '@ng-select/ng-select/themes/default.theme.css' as *;

body {
  margin: 0;
  overflow: hidden;
  font-size: 14px;
  font-family: $content-font-family;
  background: var(--white);
  color: var(--h-gray-800);
}

.content {
  height: calc(100vh - 80px);
  margin: 10px;
  padding: 20px;
  background: $white-color;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.08);
}

.hidden {
  display: none;
}

.heading {
  font-weight: 600;
  font-size: 20px;
}

.title {
  color: $title-color;
  font-weight: 500;
  font-size: 18px;
  font-style: normal;
}

.rounded-border {
  border-radius: 4px;
}

.base-info-button {
  margin-right: 10px;
}

.comment-field {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  width: 416px;
  height: 64px;
  background: #ffffff;
  border: 1px solid #e1e9f5;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  appearance: none;
  outline: none;
  resize: none;
  margin-top: 20px;

  &:focus {
    border: 1px solid #0070d8;
  }
}

.modal-chip {
  padding: 2px 8px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--neutral-disable-button-color, #e1e9f5);
  background: #fcfdff;
  color: var(--neutral-black-color, #000);
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;

  &.--active,
  &:hover {
    padding: 2px 8px;
    border-radius: 4px;
    border: 1px solid var(--primary-blue-color-pressed, #0070d8);
    background: #fcfdff;
    color: var(--neutral-black-color, #000);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
  }

  &.--disabled {
    color: var(--neutral-disable-text-color, #ccd5e2);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    border-radius: 4px;
    border: 1px solid var(--neutral-disable-button-color, #e1e9f5);
    background: #fcfdff;
  }
}

.btn {
  display: flex;
  align-items: center;
  padding: 9px 20px 9px 18px;
  color: $white-color;
  font-weight: 400;
  font-size: 13px;
  background: $button-active;
  border-radius: 4px;

  &.--fill-container {
    flex-grow: 1;
  }

  &.--small {
    padding: 5px 9px;
  }

  &.secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;

    &:disabled {
      border-radius: 4px;
      background-color: var(--neutral-disable-button-color, #e1e9f5);
      color: var(--neutral-disable-text-color, #ccd5e2);
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      cursor: not-allowed;
    }

    &:not(:disabled).emphasis {
      border-radius: 4px;
      background-color: var(--primary-blue-color-normal, #007cf0);
      color: var(--neutral-white-color, #fff);
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
    }

    &:not(:disabled).subtle {
      border-radius: 4px;
      border: 1px solid var(--primary-blue-color-normal, #007cf0);
      background-color: var(--neutral-white-color, #fff);
      color: var(--primary-blue-color-normal, #007cf0);
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
    }

    &:not(:disabled).ghost {
      color: var(--primary-blue-color-normal, #007cf0);
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      background-color: transparent;
      border: 0;
    }

    &:not(:disabled).ghost-outlined {
      background-color: transparent;
      color: var(--primary-blue-color-normal, #007cf0);
      border: 1px solid var(--primary-blue-color-normal, #007cf0);
      font-size: 14px;
      line-height: 20px;
      font-style: normal;
      font-weight: 600;
    }

    &:not(:disabled):hover {
      border-radius: 4px;
      background-color: var(--primary-blue-color-hover, #3396f3);
      color: var(--neutral-white-color, #fff);
      font-style: normal;
      font-weight: 600;
      border-color: transparent;
    }

    &:not(:disabled):active {
      border-radius: 4px;
      background-color: var(--primary-blue-color-pressed, #0070d8);
      color: var(--neutral-white-color, #fff);
      font-style: normal;
      font-weight: 600;
      border-color: transparent;
    }

    &.pressed {
      border-radius: 4px;
      background-color: var(--primary-blue-color-pressed, #0070d8);
      color: var(--neutral-white-color, #fff);
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
    }

    &.--blue {
      background-color: $button-active;
      color: white;
    }

    &.--transparent {
      background: transparent;
      color: $button-active;
    }
  }

  &.--blue {
    background-color: $button-active;

    &:hover {
      background-color: $button-hover;
    }

    &:active {
      background-color: #0070d8;
    }
  }

  &.--red {
    background-color: #ff5252;
  }

  &.--green {
    background-color: $button-green;
  }

  &.--orange {
    background-color: $button-orange;
  }

  // TODO: REFACTOR
  &.--white {
    background-color: white;
    color: #21304e;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;

    &:hover {
      background-color: $button-hover;
      color: var(--Neutral-White-color, #fff);
    }

    &:active {
      background-color: #0070d8;
      color: var(--Neutral-White-color, #fff);
    }
  }

  &.--gray {
    border-radius: 8px;
    background-color: var(--Neutral-Background-color, #f4f8fe);
    transition:
      background-color 0.3s ease,
      color 0.3s ease;

    color: var(--Neutral-Deep-blue-color, #21304e);
    text-align: center;
    font-feature-settings:
      'liga' off,
      'clig' off;

    /* Кнопки/Текст для кнопок */
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 123.077% */

    &:hover {
      background-color: var(--Neutral-Grey-color-2, #808ea9);
      color: var(--Neutral-White-color, #fff);
    }
  }

  &.--transparent {
    color: $black-color;
    background-color: transparent;
  }

  &.--with-outline {
    color: $black-color;
    background: #ffffff;
    border: 1px solid #d6e1f8;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:enabled {
    opacity: 1;
  }
}

.form-textarea {
  padding: 7px 12px;
  border: 1px solid $textarea-border;
  border-radius: 4px;
  font-size: 13px;
  color: $black-color;

  &::placeholder {
    color: $textarea-placeholder;
  }
}

.top-border-line {
  border-top: 1px solid $complaint-border;
  margin-top: 25px;
  padding-top: 20px;
}

/*
** Styling Scrollbars with CSS
** Works on Chrome/Edge/Safari
*/

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  margin-right: 1px;
}

*::-webkit-scrollbar-track {
  background-color: $scrollbar-track-color;
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb-color;
  border: 4px solid $scrollbar-thumb-color;
  border-radius: 4px;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #cef;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.whatsapp-modal {
  .mat-mdc-dialog-surface {
    padding: 0 !important;
    background-color: white !important;
    overflow: inherit;
  }
}

.notifications-center-popup {
  .mat-mdc-dialog-surface {
    background: #0000;
    padding: 0;
    border: 0;
    overflow: hidden;
    height: auto;
  }
}

.custom-intro {
  color: white;
  border-radius: 10px;
  display: flex;
  width: 400px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .buttons {
    position: absolute;
    bottom: 10px;
    left: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
}

.custom-intro h2,
.introjs-tooltip-title {
  color: var(--neutral-background-color, #f4f8fe);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.custom-intro p {
  color: var(--neutral-disable-button-color, #e1e9f5);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.custom-intro button {
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid var(--additional-blue-color-light-1, #a8d5ff);
}

.intro-close {
  color: var(--additional-blue-color-light-1, #a8d5ff);
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid var(--additional-blue-color-light-1, #a8d5ff);

  &:hover {
    background-color: white;
    color: #007cf0;
    border-color: transparent;
  }
}

.intro-try,
.intro-next {
  background-color: white;
  color: var(--primary-blue-color-normal, #007cf0);
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;

  &:hover {
    background-color: var(--primary-blue-color-normal, #007cf0);
    color: white;
  }
}

.important-info-container {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-disable-button-color, #e1e9f5);

  svg {
    min-width: 24px;
    min-height: 24px;
  }

  .important-info {
    color: var(--neutral-disable-button-color, #e1e9f5);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 133.333% */
  }
}

.custom-intro-tooltip .introjs-tooltip-header {
  padding: 16px 16px 0;
}

.custom-intro-tooltip .introjs-helperNumberLayer {
  position: absolute;
  left: 16px;
  bottom: 6px;
  color: var(--additional-blue-color-light-1, #a8d5ff);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
}

.custom-intro-tooltip .introjs-skipbutton {
  color: white;
}

.custom-intro-tooltip .introjs-tooltiptext {
  padding: 0;
}

.custom-intro-tooltip .introjs-tooltip {
  background-color: #007cf0;
}

.custom-intro-tooltip .introjs-button {
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid var(--additional-blue-color-light-1, #a8d5ff);
  text-shadow: none;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }

  &.introjs-nextbutton {
    background-color: white;
    color: var(--primary-blue-color-normal, #007cf0);
    text-align: center;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    border: 1px solid var(--additional-blue-color-light-1, #a8d5ff);

    &:hover {
      background-color: var(--primary-blue-color-normal, #007cf0);
      color: white;
      border: 1px solid var(--additional-blue-color-light-1, #a8d5ff);
    }
  }

  &.introjs-disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: white;
      color: var(--primary-blue-color-normal, #007cf0);
    }
  }

  &.introjs-prevbutton {
    display: none;
  }
}

.custom-intro-tooltip .introjs-arrow.left {
  border-right-color: #007cf0;
}

.custom-intro-tooltip .introjs-arrow.top-right {
  border-color: transparent;
  border-bottom-color: #007cf0;
}

.custom-intro-tooltip .introjs-tooltipbuttons {
  border-top: none;
}

.introjs-tooltip.custom-intro-tooltip {
  background-color: #007cf0;
  width: 400px;
  max-width: none;
  min-width: auto;
}

.introjs-helperLayer {
  box-shadow:
    0 0 0 0 rgba(33, 33, 33, 0),
    0 0 0 5000px #040930bd !important;
}

.introjs-overlay {
  background: rgba(4, 9, 48, 0.74) !important;
}

.introjs-skipbutton {
  display: none !important;
}


.zenmode-welcome-popup-container .mat-mdc-dialog-surface {
  background: var(--neutral-background-color, #f4f8fe);
}

.zenmode-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {
      th {
        background-color: $zenmode-table-header-bg;
        color: $zenmode-table-header-color;
        border: 1px solid $zenmode-table-border-color;
        padding: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:first-child {
          width: 50px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px;
        border: 1px solid $zenmode-table-border-color;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 150px;

        &:first-child {
          width: 50px;
        }

        &:hover {
          background-color: $primary-blue-color-hover;
          color: white;
          transition: $zenmode-table-transition;
        }
      }

      &.selected {
        background-color: $zenmode-table-row-selected-bg;
      }
    }
  }
}

.mat-tab-body-content {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.mat-tab-body-active .mat-tab-body-content {
  opacity: 1;
}

.cdk-global-scrollblock {
  position: static;
  width: auto;
  overflow-y: unset;
}

.modal-panel-rounded .mat-mdc-dialog-surface {
  border-radius: 12px !important;
  padding: 16px;
}

.modal-panel-zero-padding .mat-mdc-dialog-surface {
  padding: 0 !important;
}

.modal-panel-transparent-bg .mat-mdc-dialog-surface {
  padding: 0 !important;
  background: transparent !important;
  border: none;
  box-shadow: none;
}

.custom-backdrop {
  background: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease-out;

  &.show {
    opacity: 0.5;
  }
}

.overlay-panel {
  background-color: white;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  // transition:
  //   transform 0.3s ease-in-out,
  //   opacity 0.3s ease-in-out;
  // transform: translateX(100%);
  // opacity: 0;

  &.show {
    transform: translateX(0);
    opacity: 1;
  }
}

.mdc-tooltip {
  .mdc-tooltip__surface {
    max-width: 100%;
    display: inline-flex;
    padding: 4px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    border-radius: 8px !important;
    background: var(--Neutral-Deep-blue-color, #21304e) !important;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.08);

    color: var(--Neutral-Background-color, #f4f8fe) !important;
    font-feature-settings:
      'liga' off,
      'clig' off;

    font-size: 13px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 16px !important;
  }

  &.text-pre .mdc-tooltip__surface {
    white-space: pre;
  }
  &.text-left .mdc-tooltip__surface {
    text-align: left;
  }
}

#tourist-tags-modal {
  .mat-mdc-dialog-surface {
    padding: 0 !important;
    border: 0 !important;
  }
}

.cdk-overlay-scrollable {
  overflow-y: auto;
}

// Общие стили для чатов, т.к. они есть и в сделках и в чатах
#chatScroller {
  // Чтобы контент не прыгал при появлении скроллбара
  scrollbar-gutter: stable;
  // Нужен потемнее скроллбар а то сливается с фоном
  &::-webkit-scrollbar-thumb {
    background-color: #a9b2bf;
    border: 4px solid #a9b2bf;
  }
}

.emoji {
  display: inline-block;
  margin: -3px 1px 0;
  vertical-align: top;
  width: 20px;
  height: 20px;
  background: url('./assets/img/emoji/apple/32.png');
  background-size: 6100% 6000%;
}

.chat-background {
  background: var(--chat-background);
  position: relative;
}

.message-status-placeholder {
  display: inline-block;
  height: 0;
  width: 45px;
  color: transparent;
  &.with-edited {
    width: 90px;
  }
}

.cdk-overlay-container {
  z-index: 10001;
}

.brx-dialog-panel-minimized {
  z-index: 999;

  .mat-mdc-dialog-surface {
    box-shadow: none;
    border-radius: 0 !important;
  }
}
