@use '../abstracts/variables' as *;

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
a,
address,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
b,
u,
i,
center,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
section,
time,
audio,
video,
input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

* {
  -webkit-backface-visibility: hidden;
}

button,
label,
[role='button'] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/** special for Safari */

input,
input:before,
input:after,
textarea,
textarea:before,
textarea:after {
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

input,
textarea,
select,
button {
  font: inherit;
}

button {
  padding: 0;
  color: inherit;
  background: none;
  border: 0;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

select {
  background: none;
  border: 0;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a {
  color: $link-color;
  border: 0;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

input[type='text'],
input[type='button'],
textarea {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="text"]::-webkit-input-placeholder {
  color: #000000;
}
input[type="text"]::-moz-placeholder {
  color: #ccc;
}
input,
textarea {
  color: $black-color;
  background-color: transparent;
}

a,
button,
label,
*[role='button'],
input[type='button'] {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 100, 255, 0.2);
}
