@use "../abstracts/variables" as *;

.mat-mdc-dialog-surface {
  background-color: $modal-background;
  border: 1px solid $modal-border-color;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  box-shadow: none;
}
#tour-packages-for-mark-as-audio-review-popup.mat-mdc-dialog-surface {
  padding: 0;
}
#sms-popup .mat-mdc-dialog-surface {
  padding: 20px 25px;
  overflow: visible;

  .form-group {
    max-width: 430px;
    &.form-group-select {
      .popup-input-group {
        input {
          width: 430px;
        }
      }
    }
  }
}

.cdk-overlay-container .last-review-modal-panel {
  border-radius: 12px;
  overflow: hidden;
}
