@keyframes marker-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.marker-bounce-animation {
  animation: marker-bounce 0.8s infinite;
}

.map-marker {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);

    .booking-rating {
      fill: #007cf0;
    }
  }
}


.draw-polygon-button {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 4px 12px;
  border-radius: 8px;
  background-color: #007cf0;
  color: #fff;
  width: 130px;
  cursor: pointer;

  &:hover {
    background-color: #3396f3;
  }

  &.active {
    &:after {
      content: '';
      background-color: #fc8800;
      position: absolute;
      top: -3px;
      right: -3px;

      width: 8px;
      height: 8px;
      border-radius: 8px;
    }
  }
}
