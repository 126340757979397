@use 'sass:list';

// Mixins
@mixin transition_basic($properties...) {
  $transition-list: ();
  @each $prop in $properties {
    $transition-list: list.append($transition-list, $prop 0.2s ease, comma);
  }
  transition: $transition-list;
}

// color palette
$gray-0: #cdd3e4;

$ht-main-header-bg-color: #3094e6;

//typography
$content-font-family: "Lato", "Roboto", sans-serif;
$content-font-size: 15px;

// Colors
// $black-color: #000;
$black-color: #21304E;
$white-color: #fff;

$link-color: #007cf0;

$light-blue: #bddfff;
$light-blue-100: #7689a7;
$light-blue-150: #cce5fc;
$light-blue-250: #d2deef;
$light-blue-200: #4faaff;
$light-blue-400: #e3f0fc;

$blue: #0084ff;
$blue-100: #bcdfff;
$blue-200: #007cf0;
$blue-400: #DEEFFF;

$green-color: #48c262;
$orange-color: #FFB800;
$light-green: #cbf7d5;
$green-color-100: #48c262;

$black-100: #212b35;
$black-200: #082c2e;
$black-300: #434851;
$black-100: #113438;

$gray: #e8ecf2;
$gray: #e3e9ee;
$gray-100: #808b9d;
$gray-200: #ababab;
$gray-300: #98a3b5;
$gray-color: #596A7A;

$light-gray: #f4f8fe;
$light-gray-100: #dfe4e8;
$light-gray-150: #d8e2ea;
$light-gray-175: #e1e9f5;
$light-gray-200: #e9ecef;
$light-gray-225: #cfd4d6;
$light-gray-250: #777777;
$light-gray-300: #919eab;
$light-gray-400: #90a0b7;
$light-gray-500: #a6b6c3;
$light-gray-550: #6E757E;
$light-gray-600: #C4C4C4;

$gray-400: #e0e4ea;
$gray-500: #e7effb;
$gray-dark: #71899e;
$dark-gray: #333333;
$dark-gray-100: #bdc0c4;
$light-gray-100: #e5edf9;
$light-gray-300: #ababab;
$gray-border: $light-gray-175;

$yellow-button: #ffe58a;
$yellow-span: #ffe58a;

$accent-color: #007CF0;
$attention-color: #ff5252;
$font-light-blue: #44739f;
$border-color: $dark-gray-100;

$content-font-color: $black-color;
$content-bg: #F1F5FB;
$date-highlight: $gray-300;

$manager-status--available: #48c262;
$manager-status--busy: #ff5252;
$manager-status--offline: #a2a2a2;

$title-color: $black-color;

$nav-bg-color: #121B2C;

$aside-bg-active: #21304E;
$aside-bg-hover: #21304E;
$aside-submenu-bg: #2e325b;
$aside-link-font: #808EA9;
$aside-link-font-hover: #fff;
$aside-link-item-active: #454A7B;

$aside-submenu-color-green: #48c262;
$aside-submenu-color-red: #ff5252;
$aside-submenu-color-orange: #ff7800;
$aside-submenu-color-violet: #b400ff;
$aside-submenu-color-blue: $blue;

$aside-bg-whatsapp: #F4F8FE;


$button-active: $blue-200;
$button-hover: rgba(#007cf0, 0.8);
$button-pressed: rgba(0, 0, 0, 0.1);
$button-green: $green-color;
$button-red: $green-color;
$button-orange: $orange-color;
$button-primary-color: $white-color;
$button-secondary: #007CF0;

$link-color: $blue-200;

$red: #ffefef;
$red-color: #FF5252;
$red-100: #f87784;

// forms
$modal-background: #ffffff;
$modal-background-accent: #EAF0F9;
$modal-border-color: $light-gray-100;

$form-error-color: $red;
$form-error-border-color: $red-100;
$form-focus-color: $blue-200;

$input-text-color: $black-color;
$radio-button-checked: $blue;
$form-element-hover-color: $light-gray;
$form-element-background: $light-blue-400;
$tour-package-chips-bg: $light-gray;
$reservations-table-header-bg: $light-gray;
$recommendations-table-header-bg: $light-gray;
$actions-table-header-bg: $light-gray;
$placeholder: $gray-200;

$textarea-border: $gray;
$textarea-placeholder: $gray-300;

$action-bg: $light-green;
$action-text: $black-200;
$action-text-color: $black-100;

$calendar-day-font-color: $black-100;
$calendar-today: $blue-400;
$calendar-day-highlight: $blue-100;
$calendar-timepad-active: #EAF0F9;
$calendar-hover-day: $light-gray-200;
$calendar-title: $black-100;
$calendar-border-color: $light-gray-100;
$calendar-time-color: $light-gray-300;
$calendar-timeline-event-bg: $light-gray;
$calendar-now-indicator: $blue-200;
$calendar-form-control-color: $gray-100;
$calendar-selected-time: $light-gray;

//Scrollbar
$scrollbar-track-color: $light-gray;
$scrollbar-thumb-color: $light-blue-250;

//Audio player
$duration-color: $gray-300;
$playback-speed-control-text: $white-color;
$playback-speed-control-bg: $light-blue-200;
$time-line-bg: $light-blue-150;
$progress-line: #f7b90b;
$handle-bg: $blue-200;
$table-header-bg: $light-gray;
$table-row-bg: $light-gray;
$form-placeholder: $light-gray-300;
$pause-icon-color: $blue-200;

// Notifications
$chip-bg: #F4F8FE;
$chip-label-color: $light-blue-100;
$button-bg: $yellow-button;

// Calls
$return-to-call-block-bg: $green-color;
$return-to-call-text-color: $white-color;

// Search results
$search-result-bg: $light-gray;

// Complaints
$complaint-bg: $light-gray;
$discuss-count-bg: $attention-color;
$complaint-border: $gray-400;
$manager-name-tag-bg: $gray-500;
$manager-name-tag-color: $gray-300;
$discuss-date-color: $gray-300;

// Form notify
$success-notify-border-color: $green-color-100;
$error-notify-border-color: $attention-color;


// Tooltip
$tooltip-text-color: $light-gray-550;
$tooltip-border-color: $light-gray-600;
$tooltip-bg: $gray;

// zenmode
$primary-blue-color-hover: #3396F3;
$neutral-background-color: #F4F8FE;
$zenmode-table-border-color: #D8E2EA;
$zenmode-table-header-bg: $neutral-background-color;
$zenmode-table-header-color: #687790;
$zenmode-table-row-selected-bg: $neutral-background-color;
$zenmode-table-transition: 0.3s;

// CSS переменные
:root {
  // Размеры блоков
  --aside-menu-width: 90px;
  --chat-list-width: 400px;
  --left-blocks-width: calc(var(--aside-menu-width) + var(--chat-list-width));

  --neutral-black-color: #21304E;

  --h-color-inverted-0: #000;
  --h-color-inverted-300: #596A7A;
  --h-color-inverted-500: #808EA9;
  --h-color-inverted-700: #D2DDF1;
  --h-color-inverted-750: #E1E9F5;
  --h-color-inverted-800: #F4F8FE;
  --h-color-inverted-999: #FFF;

  // Цвета по семантическому значению
  --h-color-primary-500: #007CF0;
  --h-color-primary-800: #21304E;

  --h-color-info-500: #4faaff;

  --h-color-success-200: #A9D998;
  --h-color-success-500: #48C262;
  --h-color-success-800: #008069;
  --h-color-success-900: #0E5548;

  --h-color-warning-300: #F9E3B2;
  --h-color-warning-500: #FFC700;

  --h-color-danger-200: #FF8888;
  --h-color-danger-250: #FF826C;

  --h-color-magic-500: #D37DD3;
}

// Chats
:root {
  --h-top-menu-height: 50px;
  --h-content-height: calc(100vh - var(--h-top-menu-height));

  // Chat list
  --white: #ffffff;
  //--h-gray-175: #f8f8f8;
  --h-gray-200: #F4F8FE;
  --h-gray-210: #EDF4FF;
  --h-gray-300: #E1E9F5;
  --h-gray-310: #D2DDF1;
  --h-gray-320: #CCD5E2;
  --h-gray-500: #808EA9;
  --h-gray-700: #596A7A;
  --h-gray-800: #21304E;
  --h-gray-850: #0E1A33;
  --h-gray-870: #121B2C; // nav-bg
  --h-gray-999: #000000;

  --h-blue-400: #3396f3;
  --h-blue-500: #007CF0;
  --h-blue-600: #0070d8;
  --h-blue-900: #202447;

  --h-red-100: #F7DEE2;

  --h-card-purple-200: #BB9EE6;
  --h-card-purple-700: #9B51E0;
  --h-card-blue-600: #007CF0;

  --h-card-yellow-400: #F8D57D;
  --h-card-red-200: #FF8888;
  --h-card-red-300: #F65C05;

  --h-card-green-250: #96EAA8;
  --h-card-green-400: #25D366;
  --h-card-green-700: #2AAF47;

  // common vars
  --skeleton-color: var(--h-gray-200);

  // tourist status colors
  --tourist-gold: #ffc700;
  --tourist-diamond: #d37dd3;

  // MODULE / COMPONENT LEVEL vars

  // todo: global menu component
  --h-menu-bg: var(--white);
  --h-menu-text: var(--h-gray-800);
  --h-menu-shadow: 0 1px 5.4px 0 rgba(1, 59, 113, 0.1); // todo:
  --h-menu-item-bg-hover: var(--h-gray-300);

  // todo: label component
  --h-label-default: var(--h-gray-500);
  --h-label-danger: #F03A3A;
  --h-label-warning: #FFC530;
  --h-label-success: #61DA7B;

  // todo: tooltip component
  --h-tooltip-bg-color: var(--h-blue-900);
  --h-tooltip-text-color: var(--h-gray-200);
  --h-tooltip-text-color-2: var(--h-gray-300);

  // todo: уровень компонента
  --h-badge-bg-color: var(--white);
  --h-badge-text-color: var(--h-gray-700);
  --h-badge-shadow: 0 0 2px 0 rgba(0, 124, 240, 0.10);
  --h-badge-bg-color-on-hover: var(--h-gray-800);
  --h-badge-text-color-on-hover: var(--white);

  // Изменение прозрачности картинки фона делается командой
  // cd apps/frontend/src/assets/img
  // magick chat-bg-pattern.png -alpha on -channel A -evaluate multiply 0.4 +channel chat-bg-pattern-transparent.png
  --chat-background: center / 400px repeat url("../../../assets/img/chat-bg-pattern-transparent.png"), #efeae2;

  // Message from client
  --message-from-client-background-color: #FFF;
  --message-from-client-background-color-rgb: 255, 255, 255;

  // Message from me
  --message-from-me-background-color: #d9fdd3;
  --message-from-me-background-color-rgb: 217, 253, 211;
  --message-from-me-offer-background-color: #FFF5DC;
  --message-from-me-offer-background-color-rgb: 255, 245, 220;

  // Общие стили сообщений
  --message-footer-color: #808EA9;
  --message-border-radius: 16px;
  --message-btn-show-menu-padding: 8px;
}
